import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';
import * as PatientAction from '../../actions/patientActions';
import { Box, Content } from '../../components/Elements';
import * as HELPER from '../../utils/helpers';
import _ from 'lodash';

class ViewPatientPage extends React.Component {
    componentDidMount() {
        if (HELPER.hasRole(this.props.admin, ['super-admin'])) {
            const { match: { params } } = this.props;
            const patientId = params.patientId;

            this.props.fetchPatient(patientId);
        }
    }

    render() {
        const { patient } = this.props;

        return <div>
            <Helmet>
                <title>View Patient</title>
            </Helmet>
            <Content title="Patient">
                <Box>
                    <Box.Head title="# Patient" />
                    <Box.Body>
                        {patient && patient.patient_id && <Table responsive striped>
                            <tbody>
                                <tr>
                                    <th>Full Name</th>
                                    <td>{patient.full_name}</td>
                                </tr>
                                <tr>
                                    <th>Father Name</th>
                                    <td>{patient.father_name ? patient.father_name : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Mother Name</th>
                                    <td>{patient.mother_name ? patient.mother_name : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Mother Maiden Name</th>
                                    <td>{patient.maiden_name}</td>
                                </tr>
                                <tr>
                                    <th>Gender</th>
                                    <td>{_.capitalize(patient.gender)}</td>
                                </tr>
                                <tr>
                                    <th>Date Of Birth</th>
                                    <td>{HELPER.getDateFormat(patient.date_of_birth)}</td>
                                </tr>
                                <tr>
                                    <th>Place Of Birth</th>
                                    <td>{patient.place_of_birth ? _.capitalize(patient.place_of_birth) : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Contact Number</th>
                                    <td>{patient.contact_number}</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{patient.email}</td>
                                </tr>
                                <tr>
                                    <th>Alternate Number/Landline Number</th>
                                    <td>{patient.alt_contact_number ? patient.alt_contact_number : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Address Line 1</th>
                                    <td>{patient.address && patient.address.address_line_1}</td>
                                </tr>
                                <tr>
                                    <th>Address Line 2</th>
                                    <td>{patient.address && patient.address.address_line_2 ? patient.address.address_line_2 : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Address Line 3</th>
                                    <td>{patient.address && patient.address.address_line_3 ? patient.address.address_line_3 : '-'}</td>
                                </tr>
                                <tr>
                                    <th>City/Community</th>
                                    <td>{patient.address && patient.address.city}</td>
                                </tr>
                                <tr>
                                    <th>Zip Code</th>
                                    <td>{patient.address.pincode}</td>
                                </tr>
                                <tr>
                                    <th>Parish/State/Province</th>
                                    <td>{patient.address && patient.address.parish}</td>
                                </tr>
                                <tr>
                                    <th>Country</th>
                                    <td>{patient.address && patient.address.country}</td>
                                </tr>
                                <tr>
                                    <th>Onboard Date</th>
                                    <td>{HELPER.getDateFormat(patient.created_at)}</td>
                                </tr>
                                <tr>
                                    <th>Next of Kin</th>
                                    <td>{patient.kin_name ? patient.kin_name : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Relationship with Kin</th>
                                    <td>{patient.kin_relation ? patient.kin_relation : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Kin's Contact Number</th>
                                    <td>{patient.kin_number ? patient.kin_number : '-'}</td>
                                </tr>
                                <tr>
                                    <th>Nearerst Police Station</th>
                                    <td>{patient.nearest_police_station ? patient.nearest_police_station : '-'}</td>
                                </tr>
                            </tbody>
                        </Table>}
                    </Box.Body>
                </Box>
            </Content>
        </div>
    }
}

const mapStateToProps = (state) => ({
    patient: state.patient,
    admin: state.auth.admin
});

const mapActionsToProps = ({
    fetchPatient: PatientAction.fetchpatient
});

export default connect(mapStateToProps, mapActionsToProps)(ViewPatientPage);
