import React from 'react';
import Location from "./Location";
import { Button, ButtonToolbar, Well } from 'react-bootstrap';
import Notification from "../../utils/notification";
import SimpleReactValidator from 'simple-react-validator';
import { Link } from "react-router-dom";
import LocationService from "../../services/locationService";
import CountryService from "../../services/countryService";

class EditMultiLocation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            location_details: '',
            countries: []
        }
        this.locationValidator = new SimpleReactValidator();
        this.loadCountries();
    }

    componentDidMount() {
        this.setState({ location_details: this.props.location_details })
    }

    loadCountries = () => {
        CountryService.list().then(resp => {
            let countries = resp.data.map((items) => {
                return { 'label': items.name, 'value': items.name };
            })
            this.setState({
                countries
            });
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    handleLocationChange = (event, idx) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const location_details = this.state.location_details.map((location_detail, sidx) => {
            if (idx !== sidx) return location_detail;

            if (['name', 'contact_number', 'max_profile_allowed'].includes(name)) {
                location_detail[name] = value;
            } else if (name === 'logo') {
                const formData = new FormData();
                formData.append('logo', target.files[0]);
                formData.append('user_location_id', location_detail.user_location_id);
                formData.append('service_provider_id', location_detail.service_provider_id);

                LocationService.uploadLogo(formData).then(resp => {
                    location_detail.logo_url = resp.data.logo_url;
                }).catch(err => {
                    console.error(err.response.data)
                })
            } else {
                location_detail['address'][name] = value;
            }

            return location_detail;
        });
        this.setState({ location_details });
    }

    addMoreLocationBlock = () => {
        const location_details = this.state.location_details.concat([{
            address: {
                address_line_1: '',
                address_line_2: '',
                address_line_3: '',
                parish: '',
                city: '',
                pincode: '',
                country: '',
            },
            name: '',
            max_profile_allowed: '',
            contact_number: ''
        }]);

        this.setState({ location_details })
    }

    updateLocationBlock = (idx, locationId = null, status) => {
        let status_val = status === true ? 'deactivate' : 'activate';
        let confirm = window.confirm("Are you sure you want to " + status_val + " it?");

        if (confirm === true) {
            if (locationId !== null) {
                LocationService.updateLocationStatus(this.props.service_provider_id, locationId).then(resp => {
                    let data = resp.data;
                    let locationData = this.state.location_details;
                    locationData[idx] = data;
                    this.setState({ location_details: locationData });
                    Notification.show("success", { message: "Location " + status_val + "d successfully" });
                }).catch(error => {
                    if (error.response && error.response.status) {
                        let errorData = error.response.data;
                        Notification.show("error", errorData);
                    } else {
                        Notification.show('error', { message: "Something Went Wrong, Try Again" });
                    }
                });
            }
        }
    }

    removeLocationBlock = (idx, locationId = null) => {
        let location_details = this.state.location_details;
        location_details.splice(idx, 1);
        this.setState({ location_details: location_details });
    }

    validateLocationSubmit = (e) => {
        e.preventDefault();
        if (!this.locationValidator.allValid()) {
            this.locationValidator.showMessages();
            this.forceUpdate();
            return false;
        }
        const postData = {
            locations: this.state.location_details
        }
        LocationService.updateLocation(this.props.service_provider_id, postData).then(resp => {
            Notification.show("success", { message: 'Locations updated successfully' });
            this.props.history.push('/' + this.props.type + '/' + this.props.service_provider_id)
        }).catch(error => {
            if (error.response && error.response.status) {
                let errorData = error.response.data;
                Notification.show("error", errorData);
            } else {
                Notification.show('error', { message: "Something Went Wrong, Try Again" });
            }
        });
    }
    render() {
        const { location_details } = this.state;
        return (
            <React.Fragment>
                <Well bsSize="small">
                    {location_details && location_details.map((location_detail, idx) => (
                        <div className="row form-group" id={"location_div" + idx}>
                            <div className="col-xs-10">
                                <Location
                                    idx={idx}
                                    key={location_detail.user_location_id}
                                    location_detail={location_detail}
                                    handleLocationChange={this.handleLocationChange}
                                    provider_type={this.props.type} validator={this.locationValidator}
                                    countries={this.state.countries} />
                            </div>
                            <div className="col-xs-2">
                                {!(location_detail.user_location_id) &&
                                    <Link className="btn btn-link" onClick={this.removeLocationBlock.bind(this, idx, location_detail.user_location_id, location_detail.is_active)} id={idx}>
                                        <span className="toggle-btn"><i className="fa fa-close" aria-hidden="true"></i></span>
                                    </Link>
                                }
                                {location_detail.user_location_id &&
                                    <Link className="btn btn-link" onClick={this.updateLocationBlock.bind(this, idx, location_detail.user_location_id, location_detail.is_active)} id={idx}>
                                        <span className="toggle-btn"><i className={location_detail.is_active === true ? 'fa fa-toggle-on' : 'fa fa-toggle-off'} aria-hidden="true" ></i></span>
                                    </Link>
                                }
                            </div>
                        </div>
                    ))}
                </Well>

                <ButtonToolbar>
                    <Button bsStyle="success" bsSize="xsmall" onClick={this.addMoreLocationBlock} className="pull-right"><i className="fa fa-plus"></i> Add More</Button>
                    <Button bsStyle="info" bsSize="xsmall" className="pull-right" type="button" onClick={this.validateLocationSubmit}><i className="fa fa-check"></i> Submit</Button>
                </ButtonToolbar>
            </React.Fragment>
        )
    }
}
export default EditMultiLocation