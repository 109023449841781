import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import _ from "lodash";
import { Box, Content } from '../../components/Elements';
import AmbulanceService from '../../services/ambulanceService';
import * as LoaderAction from '../../actions/loaderAction';

class ViewAmbulancePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ambulance: '',
        };
    }
    componentDidMount() {
        const { match: { params } } = this.props;
        const serviceProviderId = params.serviceProviderId;
        this.props.showLoader();
        AmbulanceService.get(serviceProviderId).then(resp => {
            this.setState({
                ambulance: resp.data
            })
            this.props.hideLoader();
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }
    render() {
        const { ambulance } = this.state;
        let details, locations, owners;

        if (ambulance && ambulance.locations) {
            locations = <Table responsive striped>
                <thead>
                    <tr>
                        <th>Location Id</th>
                        <th>Location Name</th>
                        <th>Address</th>
                        <th>Parish/State/Province</th>
                        <th>City/Community</th>
                        <th>Zip Code</th>
                        <th>Country</th>
                    </tr>
                    {ambulance.locations.map((location) =>
                        <tr key={location.user_location_id}>
                            <td>{location.user_location_id}</td>
                            <td>{location.name}</td>
                            <td>{location.address.address_line_1 !== null ? location.address.address_line_1 : ''}
                                {location.address.address_line_2 !== null ? ', ' + location.address.address_line_2 : ''}
                                {location.address.address_line_3 !== null ? ', ' + location.address.address_line_3 : ''}</td>
                            <td>{location.address.parish}</td>
                            <td>{location.address.city}</td>
                            <td>{location.address.pincode}</td>
                            <td>{location.address.country}</td>
                        </tr>
                    )}
                </thead>
            </Table>;
        }

        if (ambulance && ambulance.owners) {
            owners = <Table responsive striped>
                <thead>
                    <tr>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Contact</th>
                        <th>Gender</th>
                        <th>Status</th>
                    </tr>
                    {ambulance.owners.map((owner, index) =>
                        <tr key={index}>
                            <td>{owner.full_name}</td>
                            <td>{owner.email}</td>
                            <td>{owner.contact_number}</td>
                            <td>{_.startCase(owner.gender)}</td>
                            <td>{owner.is_active ? 'Active' : 'In active'}</td>
                        </tr>
                    )}
                </thead>
            </Table>;
        }

        if (ambulance && ambulance.billing_address && ambulance.service_provider_id) {

            details = <Table responsive striped>
                <tbody>
                    <tr>
                        <th>Ambulance Name</th>
                        <td>{ambulance.billing_name}</td>
                    </tr>
                    <tr>
                        <th>Registration Number</th>
                        <td>{ambulance.license_number}</td>
                    </tr>
                    <tr>
                        <th>Commission Type</th>
                        <td>{ambulance.commission_type ? _.startCase(ambulance.commission_type) : "No Commission"}</td>
                    </tr>
                    <tr>
                        <th>Commission Rate</th>
                        <td>{ambulance.commission_rate}</td>
                    </tr>
                    {ambulance.commission_type === "pay_per_use" && <tr>
                        <th>Currency</th>
                        <td>{ambulance.currency}</td>
                    </tr>}
                    <tr>
                        <th>Address Line 1</th>
                        <td>{ambulance.billing_address.address_line_1}</td>
                    </tr>
                    <tr>
                        <th>Address Line 2</th>
                        <td>{ambulance.billing_address.address_line_2}</td>
                    </tr>
                    <tr>
                        <th>Address Line 3</th>
                        <td>{ambulance.billing_address.address_line_3}</td>
                    </tr>
                    <tr>
                        <th>City/Community</th>
                        <td>{ambulance.billing_address.city}</td>
                    </tr>
                    <tr>
                        <th>Zip Code</th>
                        <td>{ambulance.billing_address.pincode}</td>
                    </tr>
                    <tr>
                        <th>Parish/State/Province</th>
                        <td>{ambulance.billing_address.parish}</td>
                    </tr>
                    <tr>
                        <th>Country</th>
                        <td>{ambulance.billing_address.country}</td>
                    </tr>
                </tbody></Table>;
        }

        return <div>
            <Helmet>
                <title>View Ambulance</title>
            </Helmet>
            <Content title="Ambulance">
                <Box>
                    <Box.Head title="Ambulance Details"><Link to="/ambulances" className="btn btn-info pull-right">Back</Link></Box.Head>
                    <Box.Body>
                        {this.state.ambulance && <div>
                            <ul className="nav nav-tabs">
                                <li className="active"><a data-toggle="tab" href="#basic_details"> Basic Details</a></li>
                            </ul>
                            <div className="tab-content">
                                {<div id="basic_details" className="tab-pane fade in active">
                                    {details}
                                    <h4>Owner</h4>
                                    {owners}
                                    <h4 className="box-title">Location Details</h4>
                                    {locations}
                                </div>}
                            </div>
                        </div>}
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}
const mapActionsToProps = ({
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
});

export default connect(null, mapActionsToProps)(ViewAmbulancePage);