import React from 'react';
import { connect } from 'react-redux';
import { Content, Box } from '../../components/Elements';
import { FormGroup, ControlLabel, FormControl, Col, Button, ButtonToolbar } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import { Helmet } from 'react-helmet';
import * as CONFIG from "../../utils/config";
import { isEmpty, filter } from "lodash";
import Notification from '../../utils/notification';
import AddMultiOwner from "../../components/Owner/AddMultiOwner";
import AddMultiLocation from '../../components/Location/AddMultiLocation';
import AirlineService from '../../services/airlineService';
import * as LoaderAction from '../../actions/loaderAction';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import CommunityService from '../../services/communityService';
import CountryService from '../../services/countryService';

class AddPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            license_number: "",
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            city: "",
            pincode: "",
            parish: "",
            country: "",
            is_trn_applicable: false,
            trn_number: '',
            billing_name: '',
            location_details: [{
                address: {
                    address_line_1: '',
                    address_line_2: '',
                    address_line_3: '',
                    parish: '',
                    city: '',
                    pincode: '',
                    country: '',
                },
                name: '',
                max_profile_allowed: '',
                contact_number: "",
            }],
            owners: [{
                name_title: "",
                first_name: "",
                last_name: "",
                email: "",
                contact_number: "",
                alt_contact_number: "",
                gender: "male",
                date_of_birth: "",
            }],
            communities: [],
            selectedParisCommunity: [],
            countries: [],
        };

        this.validator = new SimpleReactValidator({
            validators: {
                birthdate: {
                    message: 'The date of birth must be less than todays date',
                    rule: (val, params) => {
                        return (new Date(val) > new Date(params) ? false : true)
                    },
                    required: true
                }
            }
        });
        this.getCommunity()
        this.loadCountries();
    }

    loadCountries = () => {
        CountryService.list().then(resp => {
            let countries = resp.data.map((items) => {
                return { 'label': items.name, 'value': items.name };
            })
            this.setState({
                countries
            });
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    getCommunity = () => {
        CommunityService.list().then(resp => {
            this.setState({
                communities: resp.data.data,
            })
        }).catch(error => {
            Notification.show('error', error.response.data)
        })
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        if (name === "is_trn_applicable") {
            if (event.target.checked) {
                this.setState({
                    is_trn_applicable: true
                });
            } else {
                this.setState({
                    is_trn_applicable: false
                });
            }
        }

        if (name === "parish") {
            this.setSelectedParisCommunity(value)
            this.setCommunity(null)
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            this.forceUpdate();
            return false;
        }

        if (this.state.is_trn_applicable === true) {
            if (isEmpty(this.state.trn_number)) {
                Notification.show('error', { message: "Please enter TRN Number" });
                return false;
            }
        }

        const postData = {
            trn_number: this.state.trn_number,
            license_number: this.state.license_number,
            is_trn_applicable: this.state.is_trn_applicable,
            billing_address: {
                address_line_1: this.state.address_line_1,
                address_line_2: this.state.address_line_2,
                address_line_3: this.state.address_line_3,
                city: this.state.city,
                pincode: this.state.pincode,
                parish: this.state.parish,
                country: this.state.country
            },
            billing_name: this.state.billing_name,
            locations: this.state.location_details,
            owners: this.state.owners
        };
        this.props.showLoader();
        AirlineService.store(postData).then(resp => {
            this.props.hideLoader();
            Notification.show('success', { message: 'Airline details has been added successfully' });
            this.props.history.push('/authority-access/' + resp.data.service_provider_id)
        }).catch(error => {
            this.props.hideLoader();
            Notification.show('error', error.response.data)
        })
    }

    resetForm = () => {
        this.setState({
            license_number: "",
            address_line_1: "",
            address_line_2: "",
            address_line_3: "",
            city: "",
            pincode: "",
            parish: "",
            country: "",
            is_trn_applicable: false,
            trn_number: '',
            location_details: [{
                address: {
                    address_line_1: '',
                    address_line_2: '',
                    address_line_3: '',
                    parish: '',
                    city: '',
                    pincode: '',
                    country: ''
                },
                name: '',
                max_profile_allowed: '',
                contact_number: "",
            }],
            owners: [{
                name_title: "",
                first_name: "",
                last_name: "",
                email: "",
                contact_number: "",
                alt_contact_number: "",
                gender: "male",
                date_of_birth: "",
            }]
        });
    }

    addMoreLocationBlock = (location_details) => {
        this.setState({ location_details })
    }

    removeLocationBlock = (location_details) => {
        this.setState({ location_details })
    }

    handleLocationChange = (location_details) => {
        this.setState({ location_details: location_details });
    }

    handleOwnerChange = (owners) => {
        this.setState({ owners })
    }

    setSelectedParisCommunity = (parish) => {
        if (parish) {
            let selectedCommunities = filter(this.state.communities, (items) => items.parish_name == parish)
            selectedCommunities = selectedCommunities.map((items) => {
                return { 'label': items.community_name, 'value': items.community_name };
            })
            this.setState({
                selectedParisCommunity: selectedCommunities
            });
        } else {
            this.setState({
                selectedParisCommunity: []
            });
        }
    }

    setCommunity = (community) => {
        this.setState({ city: community ? community.value : '' });
    }

    createCommunity = (community) => {
        let selectedParisCommunity = this.state.selectedParisCommunity;
        selectedParisCommunity.push({ label: community, value: community });
        this.setState({ city: community, selectedParisCommunity: selectedParisCommunity });
    }

    setCountry = (country) => {
        this.setState({ country: country ? country.value : '' }, () => {
            this.setState({
                parish: "",
                city: "",
                pincode: ""
            })
        });
    }

    render() {
        return <div>
            <Helmet>
                <title>Add Authority Access</title>
            </Helmet>
            <Content title="Add Authority Access">

                <Box>
                    <Box.Head title="Add Authority Access Form"><a href="/authority-access" className="btn btn-info pull-right">Back</a></Box.Head>
                    <Box.Body>
                        <form onSubmit={this.handleSubmit} onReset={this.resetForm}>
                            <h4>Billing Details</h4>
                            <hr />

                            <div className="row form-group">
                                <div className="col-xs-5">
                                    <ControlLabel>TRN Number: </ControlLabel>
                                    <FormControl
                                        name='trn_number'
                                        type="text"
                                        value={this.state.trn_number}
                                        placeholder="Enter your TRN number"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.state.is_trn_applicable ? this.validator.message('trn_number', this.state.trn_number, 'required|max:15,string') : ''}
                                </div>

                                <div className="col-xs-6">
                                    <ControlLabel>Bill Name: <span>*</span></ControlLabel>
                                    <FormControl
                                        name='billing_name'
                                        type="text"
                                        value={this.state.billing_name}
                                        placeholder="Enter your bill name"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('billing_name', this.state.billing_name, 'required|string|max:100,string')}
                                </div>
                            </div>

                            <div className="row form-group">
                                <div className="col-xs-4">
                                    <ControlLabel>Address Line 1: *</ControlLabel>
                                    <FormControl
                                        name='address_line_1'
                                        type="text"
                                        value={this.state.address_line_1}
                                        placeholder="Enter your address line"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('address_line_1', this.state.address_line_1, 'required|string|max:255,string')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Address Line 2:</ControlLabel>
                                    <FormControl
                                        name='address_line_2'
                                        type="text"
                                        value={this.state.address_line_2}
                                        placeholder="Enter your address line"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('address_line_2', this.state.address_line_2, 'string|max:255,string')}
                                </div>

                                <div className="col-xs-3">
                                    <ControlLabel>Address Line 3:</ControlLabel>
                                    <FormControl
                                        name='address_line_3'
                                        type="text"
                                        value={this.state.address_line_3}
                                        placeholder="Enter your address line"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('address_line_3', this.state.address_line_3, 'string|max:255,string')}
                                </div>
                            </div>

                            <div className="row form-group">

                                <div className="col-xs-4">
                                    <ControlLabel>Country: *</ControlLabel>
                                    <Select isClearable options={this.state.countries} id="country" value={this.state.country ? { value: this.state.country, label: this.state.country } : null} onChange={(newValue) => this.setCountry(newValue)} />

                                    {this.validator.message('country', this.state.country, 'required')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Parish/State/Province: *</ControlLabel>
                                    {
                                        this.state.country === "Jamaica" ?
                                            <FormControl name="parish" componentClass="select" value={this.state.parish} onChange={this.handleChange} placeholder="Select Parish">
                                                <option value=''>Select Parish</option>
                                                {CONFIG.PARISH.map((value) => {
                                                    return <option value={value}>{value}</option>
                                                })}
                                            </FormControl>
                                            :
                                            <FormControl
                                                name='parish'
                                                type="text"
                                                value={this.state.parish}
                                                placeholder=""
                                                onChange={this.handleChange}
                                                autoComplete="none"
                                            />
                                    }
                                    {this.validator.message('parish/state/province', this.state.parish, 'required')}
                                </div>

                                <div className="col-xs-3">
                                    <ControlLabel>City/Community: *</ControlLabel>
                                    {
                                        this.state.country === "Jamaica" ?
                                            <CreatableSelect isClearable options={this.state.selectedParisCommunity} value={{ value: this.state.city, label: this.state.city }} onChange={(newValue) => this.setCommunity(newValue)} onCreateOption={this.createCommunity} />
                                            :
                                            <FormControl
                                                name='city'
                                                type="text"
                                                value={this.state.city}
                                                placeholder=""
                                                onChange={this.handleChange}
                                                autoComplete="none"
                                            />
                                    }
                                    {this.validator.message('city/community', this.state.city, 'required|string|max:100,string')}
                                </div>
                            </div>

                            <div className="row form-group">

                                <div className="col-xs-4">
                                    <ControlLabel>Zip Code: </ControlLabel>
                                    <FormControl
                                        name='pincode'
                                        type="text"
                                        value={this.state.pincode}
                                        placeholder="Enter your Zip Code"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('pincode', this.state.pincode, 'alpha_num|max:7')}
                                </div>

                                <div className="col-xs-4">
                                    <ControlLabel>Registration Number: *</ControlLabel>
                                    <FormControl
                                        name='license_number'
                                        type="text"
                                        value={this.state.license_number}
                                        placeholder="Enter your Registration Number"
                                        onChange={this.handleChange}
                                        autoComplete="none"
                                    />
                                    {this.validator.message('registration_number', this.state.license_number, 'required')}
                                </div>

                                <div className="col-xs-3 mt-2">
                                    <ControlLabel>Is TRN applicable: &nbsp;&nbsp;</ControlLabel>
                                    <input type="checkbox" id='is_trn_applicable' name="is_trn_applicable" value={this.state.is_trn_applicable} onChange={this.handleChange} />
                                    <label htmlFor='is_trn_applicable'>&nbsp;&nbsp;Yes</label>
                                </div>
                            </div>

                            <h4>Location Details</h4>
                            <hr />
                            {this.state.location_details && <AddMultiLocation
                                location_details={this.state.location_details}
                                handleLocationChange={this.handleLocationChange}
                                validator={this.validator}
                                addMoreLocationBlock={this.addMoreLocationBlock}
                                removeLocationBlock={this.removeLocationBlock}
                            />}

                            <h4>Owners Details</h4>
                            <hr />
                            {this.state.owners && <AddMultiOwner
                                owners={this.state.owners}
                                handleOwnerChange={this.handleOwnerChange}
                                validator={this.validator}
                            />}

                            <FormGroup className="row" controlId="">
                                <Col sm={4} md={4}>
                                    <ButtonToolbar>
                                        <Button bsStyle="primary" type='submit'>Submit</Button>
                                        <button type="reset" className="btn btn-btn btn-danger"><i className="fa fa-repeat"></i> Reset</button>
                                    </ButtonToolbar>
                                </Col>
                            </FormGroup>
                        </form>
                    </Box.Body>
                </Box>
            </Content>
        </div>;
    }
}
const mapActionsToProps = ({
    showLoader: LoaderAction.loaderShow,
    hideLoader: LoaderAction.loaderHide,
});

export default connect(null, mapActionsToProps)(AddPage);
