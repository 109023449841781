export const PARISH = ['Saint Andrew', 'Kingston', 'Saint Thomas', 'Portland', 'Saint Mary', 'Saint Ann', 'Trelawny', 'Saint James', 'Hanover', 'Westmoreland', 'Saint Elizabeth', 'Manchester', 'Clarendon', 'Saint Catherine'];

// export const DOCTORDEGREE = ['MBBS', 'BDS', 'MD', 'MS', 'MDS', 'DM', 'DS', 'DNB'];
export const DOCTORDEGREE = ['AEMT','ARRT','ASCP-MLS','BDS','BPO','BSc','BSc(MLS)','CCC-SLP','CCFP','CPO','CPsychol','DABMP','DC','DDS','DFAPA','DICCP','DM','DMD','DO','DPM','DPT','EMT-P','FAAD','FAAN','FAAO','FAAOP','FAAPMR','FAARC','FACC','FACE','FACEP','FACFAS','FACG','FACNM','FACOG','FACP','FACS','FAND','FANZA','FARCPath','FASGE','FASN','FCOptom','FDSRCS','FFPMRCPS (Glasg)','FIBMS','FIDSA','FRCA','FRCEM','FRCN(SN)','FRCOG','FRCOphth','FRCP','FRCPCH','FRCPSYCH','FRCR','FRCS(CTh)','FRCS(ENT)','FRCS(Orth)','FRCS(Plast)','FRCS(Urol)','FRCS(Vasc)','IBMS','MA','MBBS','MCSP','MD','MOT','Mpharm','MPO','MPT','MRCGP','MRT','MSc','MSc(MLS)','MSP','NRP','OD','OTD','OTR/L','PharmD','PhD','PhD-SLP','PsyD','PT','RCSLT','RD','RDN','RPFT','RPh','RRT','RT(R)','MS','MDS','DS','DNB'];

// export const SPECIALIZATION = ['General Practitioner', 'Family Physician', 'Pediatrician', 'Cardiologist', 'Obstetrician/Gynecologist', 'Surgeon', 'Psychiatrist', 'Dermatologist', 'Orthopedic', 'Endocrinologist', 'Neurologist', 'Nephrologist', 'Gastroenterologist', 'Infectious Disease', 'Ophthalmologist', 'Otolaryngologist/ ENT', 'Pulmonologist', 'Radiologist', 'Oncologist', 'Anesthesiologist'];
export const SPECIALIZATION =['Anesthesiologist','Cardiologist','Dermatologist','Emergency Physician','Endocrinologist','Family Physician','General Practitioner','Gastroenterologist','Geriatrician','Hematologist','Infectious Disease Specialist','Internist','Nephrologist','Neurologist','Neurosurgeon','Obstetrician/Gynecologist','Oncologist',
'Ophthalmologist','Orthopedic Surgeon','Otolaryngologist/ ENT','Pathologist','Pediatrician','Physiatrist','Plastic Surgeon','Psychiatrist','Pulmonologist','Radiologist','Rheumatologist','General Surgeon','Thoracic Surgeon','Urologist','Vascular Surgeon','Chiropractor','Clinical Psychologist','Counseling Psychologist','Dentist','Oral Surgeon','Dietitian','Nutritionist','Medical Laboratory Technologist','Medical Physicist','Nuclear Medicine Physician','Occupational Therapist','Optometrist','Paramedic','Emergency Medical Technician (EMT)','Pharmacist','Physiotherapist (Physical Therapist)','Podiatrist','Prosthetist, Orthotist','Radiologic Technologist (X-ray, CT, MRI, Ultrasound)','Respiratory Therapist','Speech-Language Pathologist (Speech Therapist)','Surgeon'];
export const NAMETITLE = ["Dr.", "Mr.", "Mrs.", "Ms.", "Miss.", "Mast."];

export const PATHOLOGY_TEST_CATEGORIES = ['CLINICAL CHEMISTRY (BLOOD)', 'HAEMATOLOGY & BLOOD BANK', 'ENDOCRINOLOGY', 'CANCER MARKER', 'URINE CHEMISTRY', 'DRUG OF ABUSE', 'THERAPUTIC DRUGS', 'MISCELLANEOUS', 'BACTERIOLOGY', 'SEROLOGY'];

export const RADIOLOGY_TEST_CATEGORIES = ['CT SCAN', 'CT ANGIOGRAM', 'X Ray', 'MRI (1.5 T)', 'MRA', 'MRV', 'ULTRASOUND', 'FLOUROSCOPY', 'INTERVENTIONAL PROCEDURES', 'NUCLEAR MEDICINE'];

export const INSURANCE = {
    "private-insurance-sagicore": 'Sagicor',
    "private-insurance-medicus": 'Medicus',
    "private-insurance-canopy": 'Canopy',
    'jadep': 'JADEP',
    'nhf': 'NHF',
    'goj': 'GOJ',
    'other': 'Other',
    'no': 'No'
}

export const PATHOLOGY_LIMS_TEST_CATEGORIES = ['CLINICAL CHEMISTRY (BLOOD)', 'HAEMATOLOGY & BLOOD BANK','SEROLOGY' ,'ENDOCRINOLOGY','Special','	Haematology','Serology/Immunology','Microbiology','Urine','CANCER MARKER', 'URINE CHEMISTRY', 'DRUG OF ABUSE', 'THERAPUTIC DRUGS', 'Miscellaneous','Profiles','Clinical Chemistry', 'BACTERIOLOGY'];
